<template>
  <v-layout column>
    <vbt-search
      v-model="searchData"
      :items="searchFieldsConfig"
      @reset="onResetSearch"
      @search="onSearch"
    >
      <template #title-right>
        <v-layout align-center>
          <v-spacer />

          <span
            class="vbt-margin-r-10"
            style="font-size: 17px;"
          >
            All accounts
          </span>

          <v-blacker-switch
            color="info"
            class="custom-search-toggle"
            @change="changeAccountsState"
          />
        </v-layout>
      </template>
    </vbt-search>

    <vbt-content-box
      :loading="isLoading"
      :loading-error="loadingError"
      :no-content="noContent"
      :go-back-btn="goBack"
      title="Information"
    >
      <vbt-table
        :headers="headers"
        :items="returnedPackages"
        show-details-action
        @click:details="goToReturnedPackage"
      >
        <template #trackingNumber="{ item }">
          <v-layout column>
            <v-layout align-center>
              <router-link
                v-if="item.trackingNumber"
                :to="{
                  name: 'tracking',
                  query: {
                    pageNum: '1',
                    trackingNumber: item.trackingNumber,
                  },
                }"
                target="_blank"
                class="blue--text darken-1"
              >
                {{ item.trackingNumber }}
              </router-link>
              <span v-else>
                -
              </span>

              <ir-returned-package-track-barcode-viewer-activator
                v-if="item.trackingNumber"
                @click="openBarcodeViewer(item.trackingNumber)"
              />
            </v-layout>

            <v-layout
              v-if="item.vendorReturnTrackingNumber"
              align-center
            >
              <v-tooltip top>
                <template #activator="{ on }">
                  <router-link
                    :to="{
                      name: 'tracking',
                      query: {
                        pageNum: '1',
                        trackingNumber: item.vendorReturnTrackingNumber,
                      },
                    }"
                    target="_blank"
                    class="success--text darken-1"
                  >
                  <span v-on="on">
                    {{ item.vendorReturnTrackingNumber }}
                  </span>
                  </router-link>
                </template>

                Vendor Return Tracking Number
              </v-tooltip>

              <ir-returned-package-track-barcode-viewer-activator
                v-if="item.vendorReturnTrackingNumber"
                color="success--text darken-1"
                @click="openBarcodeViewer(item.vendorReturnTrackingNumber)"
              />
            </v-layout>
          </v-layout>
        </template>

        <template #orderId="{ item: { orderId: id } }">
          <router-link
            v-if="id"
            :to="{
              name: 'order',
              params: { id },
            }"
            class="blue--text darken-1"
          >
            {{ id }}
          </router-link>
          <span v-else>
            -
          </span>
        </template>

        <template #expireDate="{ item: { expireDate: _date } }">
          {{ _date | formatDate('local', '-') }}
        </template>

        <template #productReshipable="{ item: { productReshipable: _state } }">
          <v-icon
            v-if="typeof _state === 'boolean'"
            :color="_state ? 'success' : 'error'"
          >
            {{ _state ? 'check' : 'block' }}
          </v-icon>
          <template v-else>
            -
          </template>
        </template>

        <template #resendToCustomer="{ item: { resendToCustomer: _state } }">
          <v-icon
            v-if="typeof _state === 'boolean'"
            :color="_state ? 'success' : 'error'"
          >
            {{ _state ? 'check' : 'block' }}
          </v-icon>
          <template v-else>
            -
          </template>
        </template>

        <template #chargeReshipping="{ item: { chargeReshipping: _state } }">
          <v-icon
            v-if="typeof _state === 'boolean'"
            :color="_state ? 'success' : 'error'"
          >
            {{ _state ? 'check' : 'block' }}
          </v-icon>
          <template v-else>
            -
          </template>
        </template>
      </vbt-table>

      <vbt-pagination
        v-model="pageNum"
        :items-count="pagedItemsCount"
      />

      <ir-returned-package-track-barcode ref="trackBarcodeViewer" />
    </vbt-content-box>
  </v-layout>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

import { usePagination, useSearch, useDataLoading } from '@mixins/factories';

import IrReturnedPackageTrackBarcodeViewerActivator from '../_components/IrReturnedPackageTrackBarcodeViewerActivator.vue';
import IrReturnedPackageTrackBarcode from '../_components/IrReturnedPackageTrackBarcode.vue';

const dataLoadingOptions = { getterName: 'getReturnedPackages' };
const paginationOptions = { itemsName: 'returnedPackages' };
const searchOptions = {
  initialSearchDataStates: [
    'printProviderId', 'printCustomerId', 'orderId', 'trackingNumber',
  ],
  customTypes: {
    printProviderId: 'number',
    printCustomerId: 'number',
  },
};

const headers = Object.freeze([
  { text: 'Tracking Number', value: 'trackingNumber' },
  { text: 'Order ID', value: 'orderId' },
  { text: 'Provider', value: 'printProvider' },
  { text: 'Client', value: 'printCustomer' },
  { text: 'Expire Date', value: 'expireDate' },
  { text: 'Reason', value: 'reasonDescription' },
  { text: 'Reshipable', value: 'productReshipable' },
  { text: 'Resend To Client', value: 'resendToCustomer' },
  { text: 'Reshipping Accepted', value: 'chargeReshipping' },
]);

export default {
  name: 'ReturnedPackages',

  components: {
    IrReturnedPackageTrackBarcodeViewerActivator,
    IrReturnedPackageTrackBarcode,
  },

  mixins: [
    useDataLoading(dataLoadingOptions),
    usePagination(paginationOptions),
    useSearch(searchOptions),
  ],

  data() {
    return {
      trackBarcodeData: '',

      headers,
    };
  },

  computed: {
    ...mapState('returnPackages', ['returnedPackages']),

    ...mapGetters('accounts', ['clientsOptions', 'providersOptions', 'accountNameById']),

    searchFieldsConfig() {
      return [
        {
          value: 'orderId',
          label: 'Order ID',
          type: 'text',
          flex: 'lg3',
        },
        {
          value: 'trackingNumber',
          label: 'Tracking Number',
          type: 'text',
          flex: 'lg3',
        },
        {
          value: 'printProviderId',
          items: this.providersOptions,
          label: 'Provider',
          type: 'combobox',
          flex: 'lg3',
        },
        {
          value: 'printCustomerId',
          items: this.clientsOptions,
          label: 'Client',
          type: 'combobox',
          flex: 'lg3',
        },
      ];
    },
  },

  created() {
    this.getAccounts({ activeOnly: true });
  },

  methods: {
    ...mapActions('returnPackages', ['getReturnedPackages']),

    ...mapActions('accounts', ['getAccounts']),

    changeAccountsState(value) {
      this.getAccounts({ activeOnly: !value });
    },

    goToReturnedPackage({ id }) {
      this.$router.push({ name: 'returnedPackage', params: { id } });
    },

    openBarcodeViewer(trackNum) {
      this.$refs.trackBarcodeViewer.$_openDialog(trackNum);
    },
  },
};
</script>
