var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"column":""}},[_c('vbt-search',{attrs:{"items":_vm.searchFieldsConfig},on:{"reset":_vm.onResetSearch,"search":_vm.onSearch},scopedSlots:_vm._u([{key:"title-right",fn:function(){return [_c('v-layout',{attrs:{"align-center":""}},[_c('v-spacer'),_c('span',{staticClass:"vbt-margin-r-10",staticStyle:{"font-size":"17px"}},[_vm._v(" All accounts ")]),_c('v-blacker-switch',{staticClass:"custom-search-toggle",attrs:{"color":"info"},on:{"change":_vm.changeAccountsState}})],1)]},proxy:true}]),model:{value:(_vm.searchData),callback:function ($$v) {_vm.searchData=$$v},expression:"searchData"}}),_c('vbt-content-box',{attrs:{"loading":_vm.isLoading,"loading-error":_vm.loadingError,"no-content":_vm.noContent,"go-back-btn":_vm.goBack,"title":"Information"}},[_c('vbt-table',{attrs:{"headers":_vm.headers,"items":_vm.returnedPackages,"show-details-action":""},on:{"click:details":_vm.goToReturnedPackage},scopedSlots:_vm._u([{key:"trackingNumber",fn:function(ref){
var item = ref.item;
return [_c('v-layout',{attrs:{"column":""}},[_c('v-layout',{attrs:{"align-center":""}},[(item.trackingNumber)?_c('router-link',{staticClass:"blue--text darken-1",attrs:{"to":{
                name: 'tracking',
                query: {
                  pageNum: '1',
                  trackingNumber: item.trackingNumber,
                },
              },"target":"_blank"}},[_vm._v(" "+_vm._s(item.trackingNumber)+" ")]):_c('span',[_vm._v(" - ")]),(item.trackingNumber)?_c('ir-returned-package-track-barcode-viewer-activator',{on:{"click":function($event){return _vm.openBarcodeViewer(item.trackingNumber)}}}):_vm._e()],1),(item.vendorReturnTrackingNumber)?_c('v-layout',{attrs:{"align-center":""}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('router-link',{staticClass:"success--text darken-1",attrs:{"to":{
                    name: 'tracking',
                    query: {
                      pageNum: '1',
                      trackingNumber: item.vendorReturnTrackingNumber,
                    },
                  },"target":"_blank"}},[_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(item.vendorReturnTrackingNumber)+" ")])])]}}],null,true)},[_vm._v(" Vendor Return Tracking Number ")]),(item.vendorReturnTrackingNumber)?_c('ir-returned-package-track-barcode-viewer-activator',{attrs:{"color":"success--text darken-1"},on:{"click":function($event){return _vm.openBarcodeViewer(item.vendorReturnTrackingNumber)}}}):_vm._e()],1):_vm._e()],1)]}},{key:"orderId",fn:function(ref){
                  var id = ref.item.orderId;
return [(id)?_c('router-link',{staticClass:"blue--text darken-1",attrs:{"to":{
            name: 'order',
            params: { id: id },
          }}},[_vm._v(" "+_vm._s(id)+" ")]):_c('span',[_vm._v(" - ")])]}},{key:"expireDate",fn:function(ref){
          var _date = ref.item.expireDate;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(_date,'local', '-'))+" ")]}},{key:"productReshipable",fn:function(ref){
          var _state = ref.item.productReshipable;
return [(typeof _state === 'boolean')?_c('v-icon',{attrs:{"color":_state ? 'success' : 'error'}},[_vm._v(" "+_vm._s(_state ? 'check' : 'block')+" ")]):[_vm._v(" - ")]]}},{key:"resendToCustomer",fn:function(ref){
          var _state = ref.item.resendToCustomer;
return [(typeof _state === 'boolean')?_c('v-icon',{attrs:{"color":_state ? 'success' : 'error'}},[_vm._v(" "+_vm._s(_state ? 'check' : 'block')+" ")]):[_vm._v(" - ")]]}},{key:"chargeReshipping",fn:function(ref){
          var _state = ref.item.chargeReshipping;
return [(typeof _state === 'boolean')?_c('v-icon',{attrs:{"color":_state ? 'success' : 'error'}},[_vm._v(" "+_vm._s(_state ? 'check' : 'block')+" ")]):[_vm._v(" - ")]]}}])}),_c('vbt-pagination',{attrs:{"items-count":_vm.pagedItemsCount},model:{value:(_vm.pageNum),callback:function ($$v) {_vm.pageNum=$$v},expression:"pageNum"}}),_c('ir-returned-package-track-barcode',{ref:"trackBarcodeViewer"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }